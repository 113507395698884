import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import routes, { renderRoutes } from 'src/routes';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import {
    API_VERSION,
    SRC_CONNECT,
} from './components/FacebookLogin/constants/facebookApi';

library.add(fas);

import {
    WarboxProvider,
    MuiThemeProvider,
    NotistackProvider,
    CSPostHogProvider,
} from 'src/providers';
import './styles/all.css';

const history = createBrowserHistory();

const App = () => {
    useEffect(() => {
        if (typeof window.FB === 'undefined') {
            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: `${process.env.REACT_APP_FACEBOOK_CLIENT_ID}`,
                    cookie: true,
                    xfbml: true,
                    version: API_VERSION,
                });
            };
            (function (d, s, id) {
                var js,
                    fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s);
                js.id = id;
                js.src = SRC_CONNECT;
                fjs.parentNode?.insertBefore(js, fjs);
            })(document, 'script', 'facebook-jssdk');
        }
    }, []);

    return (
        <CSPostHogProvider>
            <MuiThemeProvider>
                <NotistackProvider>
                    <Router history={history}>
                        <WarboxProvider>
                            <GlobalStyles />
                            <ScrollReset />
                            <GoogleAnalytics />
                            {renderRoutes(routes)}
                        </WarboxProvider>
                    </Router>
                </NotistackProvider>
            </MuiThemeProvider>
        </CSPostHogProvider>
    );
};

export default App;
