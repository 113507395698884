import React from 'react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

if (
    process.env.REACT_APP_ENVIROMENT &&
    process.env.REACT_APP_ENVIROMENT !== 'development'
) {
    if (
        process.env.REACT_APP_PUBLIC_POSTHOG_KEY &&
        process.env.REACT_APP_PUBLIC_POSTHOG_HOST
    ) {
        posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
            api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
            person_profiles: 'identified_only',
        });
    }
}

export function CSPostHogProvider({ children }: { children: React.ReactNode }) {
    return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
